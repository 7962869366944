/* eslint-disable react/jsx-indent-props */
import { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, useMatch } from 'react-router-dom';
import { LoginLayout } from './login/LoginLayout';
import { PageNotFound } from './globals/PageNotFound';
import { InternalError } from './globals/InternalError';
import { CandidateLoginLayout } from './candidate/login/CandidateLoginLayout';
import MailToBaseLayout from './mailTo/MailToBaseLayout';
import VendorLoginLayout from './vendor/login/LoginLayout';
import VerificationLayout from './verification/VerificationBaseLayout';
import { LoadingSpinner } from './utils/LoadingSpinner';
import EmploymentFormContainer from './operations/operationCases/employment/employmentVerificationOnline/EmploymentFormContainer';
import ChooseRole from './Admin/ChooseRole';
import { updateUserInfo, PrivateRoute } from './globals/utils';
import { WithRouterHooks } from 'withFunctionHooks';
import ReportViewerBaseLayout from './reportViewer/ReportViewerBaseLayout';
import TestRest from './globals/samples/TestRest';
import DocumentViewerBaseLayout from './documentViewer/DocumentViewerBaseLayout';
import FetchGoogleAPI from 'utils/FetchUseJsApiLoader';
// import { Logger } from './globals/samples/Logger';
//billingcommented
// import BillingBaseLayout from './Admin/billing/BillingBaseLayout';

import { Chart, TimeScale, LinearScale, LineController, LineElement, PointElement } from 'chart.js';
import 'chartjs-adapter-date-fns';

// Register the time scale and other required elements
Chart.register(TimeScale, LinearScale, LineController, LineElement, PointElement);

const ClientAdminLayout = lazy(() => import('./clientAdmin/ClientAdminLayout'));
const ClientAdminInitLayout = lazy(() => import('./clientAdmin/clientinit/ClientAdminInitLayout'));
const SocialIntelligenceLayout = lazy(() => import('./clientAdmin/socialIntelligence/SocialIntelligenceLayout'));
const CandidateLayout = lazy(() => import('./candidate/CandidateLayout'));
const OperationsBaseLayout = lazy(() => import('./operations/OperationsBaseLayout'));
const AdminBaseLayout = lazy(() => import('./Admin/AdminBaseLayout'));
const ReportPreview = lazy(() => import('./caseReport/ReportPreview'));
const DataentryLayout = lazy(() => import('./dataEntry/DataEntryLayout'));
const QcLayout = lazy(() => import('./qualityCheck/QcLayout'));
const BgvLayout = lazy(() => import('./clientAdmin/createInit/caseCreation/manualCreate/BgvCaseCreation'));
const CreateCaseLayout = lazy(() => import('./clientAdmin/createInit/caseCreation/CreateCase'));

const LoginLayoutWithHooks = WithRouterHooks(LoginLayout);
const VerificationLayoutWithHooks = WithRouterHooks(VerificationLayout);
const CandidateLoginLayoutWithHooks = WithRouterHooks(CandidateLoginLayout);
const DataentryLayoutWithHooks = WithRouterHooks(DataentryLayout);
const QcLayoutWithHooks = WithRouterHooks(QcLayout);
const CreateCaseLayoutWithHooks = WithRouterHooks(CreateCaseLayout);
const BgvLayoutWithHooks = WithRouterHooks(BgvLayout);
const TestRestWithHooks = WithRouterHooks(TestRest);
const MailToBaseLayoutWithHooks = WithRouterHooks(MailToBaseLayout);
const VendorLoginLayoutWithHooks = WithRouterHooks(VendorLoginLayout);
const EmploymentFormContainerWithHooks = WithRouterHooks(EmploymentFormContainer);
const InternalErrorWithHooks = WithRouterHooks(InternalError);
const ReportPreviewWithHooks = WithRouterHooks(ReportPreview);

export default function Profile(props) {
     
     useEffect(() => {
          updateUserInfo();
     }, [])

     
     return (
          <>
               <Suspense fallback={<div><LoadingSpinner /></div>}>
                    {/* <Router> */}
                    <Routes>

                         {/* Need tpo work
                          <Route path="/home/*" element={<LoginLayoutWithHooks />} /> */}
                         <Route path="/chooseRole/*" element={<PrivateRoute cprops={props} match="/chooseRole" component={ChooseRole} />} />
                         <Route path="/onboarding/*" element={<PrivateRoute cprops={props} match="/onboarding" component={ClientAdminInitLayout} />} />
                         <Route path="/dashboard/*" element={<PrivateRoute cprops={props} match="/dashboard" component={ClientAdminLayout} />} />
                         <Route path="/socialIntelligence/*" element={<PrivateRoute cprops={props} match="/socialIntelligence" component={SocialIntelligenceLayout} />} />
                         <Route path="/residenceVerification/*" element={<VerificationLayoutWithHooks match="/residenceVerification"/>} />
                         <Route path="/adminDashboard/*" element={<PrivateRoute cprops={props} match="/adminDashboard" component={AdminBaseLayout} />} />
                         <Route path="/candidate/*" element={<PrivateRoute cprops={props} match="/candidate" component={CandidateLayout} />} />
                         <Route path="/cdHome/*" element={<CandidateLoginLayoutWithHooks match="/cdHome" />} />
                         <Route path="/dataEntry/*" element={<DataentryLayoutWithHooks match="/dataEntry" />} />
                         <Route path="/qcDashboard/*" element={<QcLayoutWithHooks match="/qcDashboard" />} />
                         <Route path="/caseCreation/*" element={<CreateCaseLayoutWithHooks match="/caseCreation" />} />
                         <Route path="/bgvCaseCreation/*" element={<BgvLayoutWithHooks match="/bgvCaseCreation" />} />
                         {/* // billingcommented 
                         // <Route path="/billing" component={BillingBaseLayout} />  */}
                         <Route path="/testRest/*" element={<TestRestWithHooks match="/testRest" />} />
                         <Route path="/mailto/*" element={<MailToBaseLayoutWithHooks match="/mailto" />} />
                         <Route path="/vendorLogin/*" element={<VendorLoginLayoutWithHooks match="/vendorLogin" />} />
                         <Route path="/operations/*" element={<PrivateRoute cprops={props} match="/operations" component={OperationsBaseLayout} />} />
                         <Route path="/vendorDashboard/*" element={<PrivateRoute cprops={props} match="/vendorDashboard" component={OperationsBaseLayout} />} />
                         <Route path="/reportViewerDashboard/*" element={<PrivateRoute cprops={props} match="/reportViewerDashboard" component={ReportViewerBaseLayout} />} />
                         <Route path="/documentViewerDashboard/*" element={<PrivateRoute cprops={props} match="/documentViewerDashboard" component={DocumentViewerBaseLayout} />} />
                         <Route path="/empVerification/*" element={<EmploymentFormContainerWithHooks match="/empVerification" />} />
                         <Route path="/500" element={<InternalErrorWithHooks match="/500" />} />
                         <Route path="/reportPreview" element={<ReportPreviewWithHooks match="/reportPreview" />} />
                         <Route path="/*" element={<LoginLayoutWithHooks match="/residenceVerification" />} />
                         <Route path="*" element={<PageNotFound match="/pageNotFound" />} />

                    </Routes>
                    {/* </Router> */}
                    {/* <Outlet/> */}
               </Suspense>

               <div id="bt-snackbar-wrapper" className="bt-snackbar-wrapper" >
                    <div id="bt-snackbar" className="bt-snackbar" />
               </div>
               <div id="bottom_alert" className="bottom_alert ">
                    <p className="msg" />
                    <svg
                         className="close_bottom_alert" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24"
                    >
                         <path d="M9.7,104l5.128-5.128,1.058-1.058a.4.4,0,0,0,0-.566l-1.132-1.132a.4.4,0,0,0-.566,0L8,102.3,1.815,96.117a.4.4,0,0,0-.566,0L.117,97.249a.4.4,0,0,0,0,.566L6.3,104,.117,110.186a.4.4,0,0,0,0,.566l1.132,1.132a.4.4,0,0,0,.566,0L8,105.7l5.128,5.128,1.058,1.058a.4.4,0,0,0,.566,0l1.132-1.132a.4.4,0,0,0,0-.566Z" transform="translate(4 -92)" />
                    </svg>
               </div>
               <FetchGoogleAPI />
          </>

     )
}

