export const protocol = 'https'
//export const protocol = 'http'
//export const domain = 'kye.meta-path.ai'
//export const domain = 'demo-metapath-639082392.ap-south-1.elb.amazonaws.com'
//export const domain = 'localhost'
export const domain = 'metapath.pyramidions.co'
//export const port = '8080'
export const port = '8443'
//export const apicontext = 'backend-0.1'
export const apicontext = 'metapath-0.1'
export const encryptionKey = new TextEncoder().encode(
    'metapath!@1234'
)
export const restbaseurl = `${protocol}://${domain}:${port}/${apicontext}`;