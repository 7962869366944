import React, { Component } from 'react';
import imageCompression from 'browser-image-compression';
import CaseProofList from '../../operations/operationCases/CaseProofList';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import BTSnackbar from '../../globals/uicomps/BTSnackbar';
export class DocumentUploadCompressor extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               showSnack: false,
               snackMsg: '',
               snackStatus: 'success',
          }
          this.previewList = [];
          this.onAttachmentFileChange = this.onAttachmentFileChange.bind(this);

     }


     async onAttachmentFileChange(e) {
          let { allowedDocsList, maxSizeMB, cbSaveAttachmentDocs } = this.props;
          let supportedCompression = ['jpg', 'jpeg', 'png']
          this.setState({ loading: true })
          let useWebWorker = 'mainThread'
          const newFileList = e.target.files;
          let fileList = [];
          let allowedExtensions = allowedDocsList;
          for (let i = 0; i < newFileList.length; i++) {
               let filename = newFileList[i].name;
               let extension = filename.substr((filename.lastIndexOf('.') + 1));
               extension = extension.toLowerCase();
               var options = {
                    maxSizeMB: maxSizeMB,
                    useWebWorker,
               }
               if (allowedExtensions.includes(extension) && (filename.includes("."))) {
                    if (supportedCompression.includes(extension)) {
                         let output = newFileList[i];
                         if (newFileList[i].size > 4084561) {
                              output = await imageCompression(newFileList[i], options);
                              const newFormData = new FormData();
                              newFormData.set('blob', output, filename);
                              output = newFormData.get('blob');
                         }
                         fileList.push({ file: output, id: new Date().getTime() + '-' + Math.random() });
                    } else {
                         fileList.push({ file: newFileList[i], id: new Date().getTime() + '-' + Math.random() });
                    }

               } else if ((filename.includes("."))) {
                    this.setState({ loading: false, showSnack: true, snackMsg: 'File extension is not supported', snackStatus: 'danger' })
               } else {
                    this.setState({ loading: false, showSnack: true, snackMsg: 'Please choose a file with extension', snackStatus: 'danger' })
               }
          }
          let attachmentList = [...this.props.attachmentList, ...fileList];
          cbSaveAttachmentDocs(attachmentList)
          this.generatePreviewList(attachmentList);

     }



     generatePreviewList = (attachmentList) => {
          let { removeFileOption } = this.props;
          this.previewList = [];
          this.previewList = attachmentList.map(fileObj => {
               if (fileObj.file.name.endsWith('.pdf')) {
                    return (
                         <div className="col-sm-3 mt10 mb10 compress_upload" key={fileObj.id}>
                              <div className="proof-doc pdf_compress">
                                   <div className="upload-file pointer mb0" style={{ background: "none" }}>
                                        <img src={"/images/component-icons/component-pdf.svg"} alt="user" ></img>
                                        <label>{fileObj.file.name}</label>
                                   </div>
                                   <div className="clr"></div>
                                   {removeFileOption ? <div className="overlay-card">
                                        <div className="btngroup">
                                             <span className="btn btn_primary" title="Remove Document" onClick={() => this.removeFileHandler(fileObj.id)} >Remove</span>
                                        </div>
                                        <div className="clr"></div>
                                   </div> : null}
                                   <div className="clr"></div>
                              </div>
                         </div>
                    )
               } else {
                    let fileReader = new FileReader();
                    let imageRef = React.createRef();
                    fileReader.onload = (e) => {
                         imageRef.current.setAttribute('src', e.target.result);
                    }
                    fileReader.readAsDataURL(fileObj.file);
                    return (
                         <div className="col-sm-3" key={fileObj.id}>
                              <div className="proof-doc compress_upload">
                                   <div className="upload-file pointer mb0" style={{ background: "none" }}>
                                        <img ref={imageRef} src="/images/docimage.png" alt="evidence" />
                                   </div>
                                   <div className="clr"></div>
                                   {removeFileOption ? <div className="overlay-card">
                                        <div className="btngroup">
                                             <span className="btn btn_primary" title="Remove Document" onClick={() => this.removeFileHandler(fileObj.id)} >Remove</span>
                                        </div>
                                        <div className="clr"></div>
                                   </div> : null}
                                   <div className="clr"></div>
                              </div>
                         </div>
                    );
               }
          })
          this.setState({ loading: false });
     }

     removeFileHandler = (id) => {
          let { cbSaveAttachmentDocs } = this.props;
          let fileList = this.props.attachmentList;
          let newFileList = fileList.filter(fileObj => fileObj.id !== id);
          cbSaveAttachmentDocs(newFileList)
          this.generatePreviewList(newFileList)
     }

     hideSnack = () => {
          this.setState({ showSnack: false, snackMsg: '', snackStatus: 'success' })
     }

     removeExistingDocument = (docName) => {
          let { existingList, cbHandleSaveExistingFile, existingObjFileName } = this.props;
          let newList = existingList.filter(docObj => docObj[existingObjFileName] !== docName);
          cbHandleSaveExistingFile(newList)
     }

     render = () => {
          let { width, height, showPreview, allowedDocs, showExistingList, existingList, allowMultipleUpload, uploadMessage, NoDocMessage,
               noWrapperClass, showUploadArea, removeFileOption, forceUnMountComponent } = this.props;
          let { showSnack, snackMsg, snackStatus, loading } = this.state
          uploadMessage = uploadMessage + `( accepts - ${allowedDocs} )`;
          if (forceUnMountComponent) {
               this.previewList = [];
          }
          return (
               <>{showSnack ? <BTSnackbar msg={snackMsg} status={snackStatus} time="2000" onHide={this.hideSnack} /> : null}
                    <div className={`${noWrapperClass ? '' : "white-bg "}  black_color`}>
                         {loading ? <>
                              < div className="col-12 col-sm-12">
                                   <div className='pad20'>
                                        <LoadingSpinner />
                                   </div>
                              </div>
                         </> : <>
                                   <div className={`${showUploadArea ? 'upload-div' : "displayNone"} `} style={{ width: width, height: height }}>
                                        {uploadMessage}
                                        <input type="file" name="file" multiple={allowMultipleUpload} onChange={this.onAttachmentFileChange} ></input>
                                   </div>
                                   {
                                        ((existingList.length > 0 || this.previewList.length > 0) && showExistingList) ?
                                             <CaseProofList documentList={existingList} hideHeader="hide" removeFileOption={removeFileOption} removeDocument={this.removeExistingDocument} />
                                             :
                                             <>
                                                  {NoDocMessage}
                                             </>
                                   }
                                   {(showPreview && this.previewList.length) ? <div className=" white-bg row">
                                        {this.previewList}
                                   </div> : null}
                              </>
                         }
                    </div>
               </>
          );
     }
}