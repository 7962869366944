import React, { Component } from 'react'
import Casereporttable from './CaseReporttable'
import { restbaseurl } from '../../../globals/constants';
import { RestDataSource } from '../../../utils/RestDataSource';
import { withTranslation } from "react-i18next";
import moment from 'moment';
import UserInfo from '../../../models/UserInfo';
import { getValue } from '../../../globals/utils';
import Axios from 'axios';
import BTSnackbar from '../../../globals/uicomps/BTSnackbar';


class Casereportdetails extends Component {
     constructor(props) {
          super(props)

          this.state = {
               userId: UserInfo.getUserid(),
               applicantName: "",
               entityDropdown: [],
               statusDropdown: [],

               dashboardFilters: [],
               entityListId: [],
               verificationTypeList: [],

               status: '',
               check: '',
               tat: '',
               checkStatus: '',
               sortBy: '',

               count: 0,
               offset: 0,
               max: 30,
               loading: false,
               loadMore: false,
               showLoadMore: 0,
               enableFilters: false,
               viewData: [],
               totalCount: 0,
               completeData: [],
               modalType: '',
               isAdmin: false,
               showSnackMessage: false,
               snackMessage: '',
               snackStatus: '',
               activeRow: -1,
               showCompleted: false,
               priorityQcData: '',
               includeSvStatus: false,
               clientId:null, 
               roleCode:'',
               isAdminOrChp:false
          }
          this.paramsObj = { offset: 0, max: 50, searchString: '', callback: this.fetchFilteredCaseList, showUnsharedReport: false, showSecondLevelQc: false, showReferBack: false };
          this.activePage = 1;
          getValue().then((pData)=>{
               this.setState({clientId:pData['clientId'], 
                              roleCode:pData['roleCode'],
                              isAdmin: pData['roleCode'] === 'admin' ? true : false,
                              isAdminOrChp:pData['isAdminOrChp']});
          })
          this.fetchStatus = {
               entitiesRequest: false,
               caseStatusRequest: false,
               caseListRequest: false,
               checkListRequest: false,
          }
     }

     computeLoadingStatus = () => {
          return Object.values(this.fetchStatus).reduce((x, y) => x || y);
     }

     changeInput = (event) => {
          this.paramsObj.searchString = event.target.value;
          if (!!this.paramsObj.searchString.length && this.paramsObj.searchString.length > 5) {
               this.handleFilterBtnClick();
          }
     }

     changeSelect = (event) => {
          const { t } = this.props;
          let value = event.target.value
          let name = event.target.name
          let newState;
          if (name === "entityListId") {
               if (value !== 'Entity') {
                    let ent = [...this.state.entityListId]
                    if (!ent.includes(value)) {
                         ent.push(value)
                         newState = { entityListId: ent };
                    }
                    event.target.value = "Entity";
               } else {
                    newState = { entityListId: [] };
               }
          }
          if (name === "status") {
               if (value !== t('casesDashboard.filters.caseStatus')) {
                    newState = { status: value };
               } else {
                    newState = { status: '' };
               }
               event.target.value = t('casesDashboard.filters.caseStatus');
          }
          if (name === "check") {
               if (value !== "Check") {
                    newState = { check: value };
               } else {
                    newState = { check: '' };
               }
               event.target.value = "Check";
          }
          if (name === "tat") {
               if (value !== "TAT") {
                    newState = { tat: value };
                    event.target.value = "TAT";
               } else {
                    newState = { tat: '' };
               }
               event.target.value = "TAT";

          }
          if (name === "checkStatus") {
               if (value !== "Check Status") {
                    newState = { checkStatus: value };
               } else {
                    newState = { checkStatus: '' };
               }
               event.target.value = "Check Status";
          }

          if (name === "sortBy") {
               if (value !== "Sort By") {
                    newState = { sortBy: value };
               } else {
                    newState = { sortBy: '' };
               }
               event.target.value = "Sort By";
          }
          this.setState(newState);

     }

     removeHandle = (e, index) => {
          let filterType = e.target.getAttribute('name');
          let filterId = e.target.id;
          let newState;
          if (filterType === "entityListId") {
               let entityFilterList = this.state.entityListId.filter(data => data !== filterId);
               newState = { entityListId: entityFilterList };
          }
          if (filterType === "status") {

               newState = { status: '' };
          }
          if (filterType === "check") {
               newState = { check: '' };
          }
          if (filterType === "checkStatus") {
               newState = { checkStatus: '' };
          }
          if (filterType === "tat") {
               newState = { tat: '' };
          }
          if (filterType === 'sortBy') {
               newState = { 'sortBy': '' };
          }
          if (filterType === "clearall") {
               newState = { entityListId: [], check: '', checkStatus: '', tat: '', status: '', sortBy: '' };
          }
          this.setState(newState);
     }

     loadMore = () => {
          let offset = this.state.dashboardFilters.length;
          this.setState({ offset: offset, loadMore: true });
     }

     componentDidMount = () => {
          // this.sendFilterRequest();
          // this.fetchFilteredCaseList();
     }

     componentDidUpdate(prevProps) {
          // if (this.props.startDate && this.props.endDate && (this.props.startDate.getTime() !== prevProps.startDate.getTime() || this.props.endDate.getTime() !== prevProps.endDate.getTime())) {
          //      this.paramsObj = { ...this.paramsObj, offset: 0, max: 50 };
          //      this.activePage = 1;
          //      // this.fetchFilteredCaseList();
          // }

          // if (prevProps.caseFilters !== this.props.caseFilters) {
          //      let caseFilters = this.props.caseFilters;
          //      let updatedFilters = { entityListId: [], check: '', checkStatus: '', tat: '', status: '', applicantName: '' };
          //      console.log("caseFilters", caseFilters)
          //      // eslint-disable-next-line no-unused-vars
          //      for (let [key, value] of Object.entries(caseFilters)) {
          //           updatedFilters[[key]] = value

          //      }
          //      this.paramsObj = { ...this.paramsObj, offset: 0, max: 50 };
          //      this.activePage = 1;
          //      // this.setState({ ...updatedFilters }, this.fetchFilteredCaseList);

          // }
     }

     sendFilterRequest = () => {
          this.fetchStatus.entitiesRequest = true;
          this.entitydataSource = new RestDataSource(`${restbaseurl}/applicantCase/entities`)
          this.setState({ loading: true }, async () => {
               this.entitydataSource.GetData(
                    data => {
                         this.fetchStatus.entitiesRequest = false;
                         this.setState({ loading: this.computeLoadingStatus() })
                         if (data.status === true) {
                              let entity = this.state.entityDropdown
                              entity.push(data.data.entityList)
                              this.setState({ entityDropdown: data.data.entityList });
                         }
                         else
                              console.log('failed');
                    },
                    { 'userId': this.state.userId, clientId: this.state.clientId, roleId: await getValue('roleId') },
                    "POST"
               );
          });
          this.fetchStatus.caseStatusRequest = true;
          this.statusdataSource = new RestDataSource(`${restbaseurl}/applicantCase/caseStatus`)
          this.setState({ loading: true }, () => {
               this.statusdataSource.GetData(
                    data => {
                         this.fetchStatus.caseStatusRequest = false;
                         this.setState({ loading: this.computeLoadingStatus() })
                         if (data.status === true) {
                              this.setState({ statusDropdown: data.data.statusList });
                              console.log("status", data)
                         }
                         else
                              console.log('failed');
                    },
                    {},
                    "POST"
               );
          })
          this.fetchStatus.checkListRequest = true;
          this.checkdataSource = new RestDataSource(`${restbaseurl}/applicantCase/batches`)
          this.setState({ loading: true }, async () => {
               this.checkdataSource.GetData(
                    data => {
                         this.fetchStatus.checkListRequest = false;
                         this.setState({ loading: this.computeLoadingStatus() })
                         if (data.status === true) {
                              this.setState({ verificationTypeList: data.data.verificationTypeList });
                         }
                         else {
                              console.log('failed');
                         }
                    },
                    {
                         "userId": this.state.userId,
                         "clientId": this.state.clientId,
                         "startDate": moment(this.props.startDate).format('YYYY-MM-DD 00:00:00'),
                         "endDate": moment(this.props.endDate).format('YYYY-MM-DD 23:59:59'),
                         roleId: await getValue('roleId')
                    },
                    "POST"
               );
          })
     }

     nameSorterAsc = (a, b) => {
          var x = a.name.toLowerCase();
          var y = b.name.toLowerCase();
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     }
     nameSorterDesc = (a, b) => {
          var x = a.name.toLowerCase();
          var y = b.name.toLowerCase();
          return ((x < y) ? 1 : ((x > y) ? -1 : 0));
     }
     tatSorterAsc = (a, b) => {
          var x = moment(a.tat);
          var y = moment(b.tat);
          return ((x.isBefore(y)) ? -1 : ((x.isAfter(y)) ? 1 : 0));
     }
     tatSorterDesc = (a, b) => {
          var x = moment(a.tat);
          var y = moment(b.tat);
          return ((x.isBefore(y)) ? 1 : ((x.isAfter(y)) ? -1 : 0));
     }

     scrollTop = () => {
          var elmnt = document.getElementById("case_report_table");
          elmnt.scrollIntoView({
               'behavior': 'smooth',
               'block': 'start',
               'inline': 'start'
          });
     }

     setActivePage = (pageNumber) => {
          this.activePage = pageNumber;
     }

     handleShowUnsharedReport = () => {
          this.paramsObj.showUnsharedReport = !this.paramsObj.showUnsharedReport;
          this.fetchFilteredCaseList();
     }

     handleSecondLevelQc = () => {
          this.paramsObj.showSecondLevelQc = !this.paramsObj.showSecondLevelQc;
          this.fetchFilteredCaseList();
     }

     handleReferBack = () => {
          this.paramsObj.showReferBack = !this.paramsObj.showReferBack;
          this.fetchFilteredCaseList();
     }

     downloadDailyStatusReport = () => {
          const { includeSvStatus } = this.state;
          let url = `${restbaseurl}/applicantCase/dailyStatusReport`;
          this.setState({ reportLoading: true });
          const config = {
               responseType: 'blob',
               withCredentials: true
          }
          Axios.post(url,
               {
                    clientId: this.state.clientId, includeSvStatus: includeSvStatus
               },
               config
          ).then((response) => {
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', 'DailyStatusReport.xlsx'); //or any other extension
               document.body.appendChild(link);
               link.click();
               this.setState({
                    reportLoading: false
               })
          });
     }

     fetchFilteredCaseList = (iParamsObj) => {
          const searchText = this.paramsObj.searchString.toLowerCase();
          const entityIdList = this.state.entityListId.map(entityId => +entityId);
          const caseStatusCode = this.state.status;
          const tatStatus = this.state.tat;
          const checkName = this.state.check;
          const checkStatusCode = this.state.checkStatus;

          const { offset, max, showUnsharedReport, showSecondLevelQc, showReferBack } = { ...this.paramsObj, ...iParamsObj };
          this.dashboardSource = new RestDataSource(`${restbaseurl}/applicantCase/dashboardFilters`);
          this.fetchStatus.caseListRequest = true;
          this.setState({ loading: true, enableFilters: false }, async () => {
               this.dashboardSource.GetData(
                    data => {
                         this.fetchStatus.caseListRequest = false;
                         if (data.status === true) {
                              this.setState({ loading: this.computeLoadingStatus(), completeData: data.data, totalCount: data.totalCount, priorityQcData: data.priorityQcData, enableFilters: true });
                         } else {
                              this.setState({ loading: this.computeLoadingStatus(), enableFilters: true, showSnackMessage: true, snackMessage: 'Failed to fetch cases', snackStatus: 'danger' });
                         }
                    },
                    {
                         "userId": this.state.userId,
                         "startDate": this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD 00:00:00') : undefined,
                         "endDate": this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD 23:59:59') : undefined,
                         "offset": offset,
                         "max": max,
                         "clientId": this.state.clientId,
                         "searchString": searchText,
                         "entityIdList": entityIdList,
                         "caseStatusCode": caseStatusCode,
                         "tatStatus": tatStatus,
                         "checkName": checkName,
                         "checkStatusCode": checkStatusCode,
                         "showUnsharedReport": showUnsharedReport,
                         "showSecondLevelQc": showSecondLevelQc,
                         "showReferBack": showReferBack,
                         "dataEntryView": this.props.dataEntryView,
                         "qcView": this.props.qcView,
                         "qcOperationsView": this.props.qcOperationsView,
                         "showCompleted": this.state.showCompleted,
                         "reportViewerView": this.props.reportViewerView,
                         "roleId": await getValue('roleId')
                    },
                    "POST"
               );
          });
     }

     handleFilterBtnClick = () => {
          this.paramsObj = { ...this.paramsObj, offset: 0, max: 50 };
          this.activePage = 1;
          this.fetchFilteredCaseList();
     }

     showChildTable = (activeRow) => {
          if (activeRow === this.state.activeRow) {
               activeRow = -1
          }
          this.setState({
               activeRow: activeRow
          })
     }

     showCompleted = () => {
          this.setState({
               showCompleted: !this.state.showCompleted
          }, () => { this.fetchFilteredCaseList() })
     }

     handleCheckBoxChanges = (e) => {
          const key = e.target.getAttribute('name');
          const value = e.target.checked;
          this.setState({ [key]: value });
     }

     render() {
          const { t } = this.props;
          const { showSnackMessage, snackMessage, snackStatus, showCompleted, priorityQcData, includeSvStatus } = this.state;
          const { showUnsharedReport, showSecondLevelQc, showReferBack } = this.paramsObj;
          let showOnlyTable = this.props.showOnlyTable ? true : false;

          let entityOptionlist = this.state.entityDropdown.map((entity, index) => {
               return <option value={entity.id} key={index} >{entity.name}</option>
          })
          let statusDropdown = this.state.statusDropdown
          if (statusDropdown.length > 2) {
               statusDropdown.sort((obj1, obj2) => {
                    return obj1.name.replace(' ', '').toLowerCase() > obj2.name.replace(' ', '').toLowerCase() ? 1 : -1
               })
          }
          let statusOptionlist = statusDropdown.map((status, index) => {
               return <option value={status.code} key={index} >{status.name}</option>
          })
          let verificationTypeList = this.state.verificationTypeList
          if (verificationTypeList.length > 2) {
               verificationTypeList.sort((obj1, obj2) => {
                    return obj1[0].replace(' ', '').toLowerCase() > obj2[0].replace(' ', '').toLowerCase() ? 1 : -1
               })
          }
          let checkOptionlist = verificationTypeList.map((checkObj, index) => {
               return <option value={checkObj[0]} key={index} >{checkObj[0]}</option>
          })


          //Tag for filters
          let showClearAll = false;

          let entlist = this.state.entityListId.map((t, i) => {
               let entityObj = this.state.entityDropdown.find(data => (data.id === Number(t)))
               showClearAll = true;
               return (
                    entityObj ? <li key={i}> <span>{entityObj.name}</span><img id={entityObj.id} name="entityListId" className="pointer" value={t} onClick={e => this.removeHandle(e, i)} src={'/images/icons/icon-close.svg'} alt="close" /></li> : ''
               );
          })

          let statusTag;
          if (this.state.status !== '') {
               showClearAll = true;
               statusTag = <li > <span>Case - {this.state.status}</span><img id={this.state.status} name="status" className="pointer" onClick={e => this.removeHandle(e, 1)} src={'/images/icons/icon-close.svg'} alt="close" /></li>
          }
          let checkTag;
          if (this.state.check !== '') {
               showClearAll = true;
               checkTag = <li > <span>{this.state.check}</span><img id={this.state.check} name="check" className="pointer" onClick={e => this.removeHandle(e, 1)} src={'/images/icons/icon-close.svg'} alt="close" /></li>
          }
          let checkStatusTag;
          if (this.state.checkStatus !== '') {
               showClearAll = true;
               checkStatusTag = <li > <span>Check - {this.state.checkStatus}</span><img id={this.state.checkStatus} name="checkStatus" className="pointer" onClick={e => this.removeHandle(e, 1)} src={'/images/icons/icon-close.svg'} alt="close" /></li>
          }
          let tatTag;
          if (this.state.tat !== '') {
               showClearAll = true;
               tatTag = <li > <span>{this.state.tat}</span><img id={this.state.tat} name="tat" className="pointer" onClick={e => this.removeHandle(e, 1)} src={'/images/icons/icon-close.svg'} alt="close" /></li>
          }
          let sortTag;
          if (this.state.sortBy !== '') {
               showClearAll = true;
               sortTag = <li > <span>Sort by  {this.state.sortBy}</span><img id={this.state.sortBy} name="sortBy" className="pointer" onClick={e => this.removeHandle(e, 1)} src={'/images/icons/icon-close.svg'} alt="close" /></li>
          }

          let clearAllTag;
          if (showClearAll) {
               clearAllTag = <li > <span>{t('Clear All')} </span><img name="clearall" value="clearall" className="pointer" onClick={e => this.removeHandle(e, 1)} src={'/images/icons/icon-close.svg'} alt="close" /></li>
          }

          let showSVStatusCheckbox = false;
          if (this.state.roleCode === 'reportviewer' || this.state.roleCode === 'chp') {
               showSVStatusCheckbox = true
          }

          let filtersTags = <React.Fragment>
               <ul className="tag-list">
                    {entlist}
                    {tatTag}
                    {statusTag}
                    {checkTag}
                    {checkStatusTag}
                    {sortTag}
                    {clearAllTag}
               </ul>
               <div className="clr20"></div>
          </React.Fragment>
          return (
               <React.Fragment>
                    {
                         showSnackMessage ? <BTSnackbar msg={snackMessage} status={snackStatus} time="2000" onHide={this.hideSnackbar} /> : ''
                    }
                    <div className="clr" id="case_report_table"></div>
                    <div id="cases-heading" className="cases-set padding-card box-shadow-card " style={{ width: '100%' }}>
                         {
                              (!showOnlyTable) ? <React.Fragment>
                                   <div className="card-hed">
                                        <h6 className="left">{t('Case Wise Status')}</h6>
                                   </div>
                                   {this.state.roleCode === "de" || this.state.roleCode === "qc" || this.state.roleCode === "operations" ? null :
                                        this.state.roleCode === "reportviewer"
                                             ? <React.Fragment>
                                                  <div className="right relative mb5">
                                                       <span className="btn btn_primary mb5" onClick={this.downloadDailyStatusReport}>Download  Status</span>
                                                       {showSVStatusCheckbox ?
                                                            <div className="form-group" id="includeSv-checkbox-div">
                                                                 <label className="container_Checkbox margin0 roundedcheckbox right">
                                                                      <span className="sub-text-color">Include SV data</span>
                                                                      <input type="checkbox" id="includeSvStatus" name="includeSvStatus" className="form-control" onChange={this.handleCheckBoxChanges}
                                                                           checked={!!includeSvStatus} />
                                                                      <span className="checkmark"></span>
                                                                 </label>
                                                            </div>

                                                            : null}
                                                  </div>
                                             </React.Fragment>
                                             : <span className="right btn btn_primary btn_lg"
                                                  onClick={() => this.props.cbChangeBaseTab('caseinitiation')}>{t('NEW CASE')}</span>
                                   }
                                   <div className="clr20"></div>
                              </React.Fragment> : ''
                         }
                         <div className="case-filters-wrapper" id="scroll_down">
                              <div className={`${this.state.roleCode === "qc" || this.state.roleCode === "operations" ? "operations" : ''} left-form-elements`}>
                                   <div className="form-group">
                                        <input type="text" className="form-control" name="applicantName" placeholder={t("SearchCandidates")} onChange={this.changeInput} />
                                   </div>
                                   {this.state.roleCode !== "de" && this.state.roleCode !== "qc" && this.state.roleCode !== "operations" ?
                                        <>
                                             <div className="form-group">
                                                  <select className="form-control" onChange={this.changeSelect} name="entityListId" disabled={!this.state.enableFilters}>
                                                       <option>{t('Entity')}</option>
                                                       {entityOptionlist}
                                                  </select>
                                             </div>
                                             <div className="form-group">
                                                  <select className="form-control" onChange={this.changeSelect} name="status" disabled={!this.state.enableFilters}>
                                                       <option>{t('casesDashboard.filters.caseStatus')}</option>
                                                       {statusOptionlist}
                                                  </select>
                                             </div>

                                             <div className="form-group">
                                                  <select className="form-control" onChange={this.changeSelect} name="tat" disabled={!this.state.enableFilters}>
                                                       <option>{t('TAT')}</option>
                                                       <option value="Within Tat" >{t('Within TAT')}</option>
                                                       <option value="Beyond Tat">{t('Beyond TAT')}</option>
                                                  </select>
                                             </div>
                                             <div className="form-group">
                                                  <select className="form-control" onChange={this.changeSelect} name="check" disabled={!this.state.enableFilters}>
                                                       <option>{t('Check')}</option>
                                                       {checkOptionlist}
                                                  </select>
                                             </div>

                                             <div className="form-group">
                                                  <select className="form-control" onChange={this.changeSelect} name="checkStatus" disabled={!this.state.enableFilters}>
                                                       <option>{t('Check Status')}</option>
                                                       {statusOptionlist}
                                                  </select>
                                             </div>
                                             <span className="btn btn_primary" onClick={this.handleFilterBtnClick}>Filter</span>
                                        </>
                                        : this.state.roleCode === "qc" || this.state.roleCode === "operations"
                                             ?
                                             <>
                                                  <div className="form-group">
                                                       <select className="form-control" onChange={this.changeSelect} name="check" disabled={!this.state.enableFilters}>
                                                            <option>{t('Check')}</option>
                                                            {checkOptionlist}
                                                       </select>
                                                  </div>
                                                  <span className="btn btn_primary" onClick={this.handleFilterBtnClick}>Filter</span>
                                             </>
                                             :
                                             ''
                                   }
                              </div>
                              <div className="clr"></div>
                         </div>
                         <div className="clr20"></div>
                         {this.state.isAdminOrChp ? <div className="form-group check_text" >
                              <label className="container_Checkbox margin0 roundedcheckbox right sub-heading2">
                                   Show Unshared Cases
                                        <input type="checkbox" id="showUnsharedReport" className="form-control" onChange={this.handleShowUnsharedReport}
                                        checked={!!showUnsharedReport} />
                                   <span className="checkmark"></span>
                              </label>
                         </div> : null}
                         {this.state.roleCode === 'qc' ?
                              <>
                                   <div className="form-group check_text " >
                                        <label className="container_Checkbox margin0 roundedcheckbox right sub-heading2">
                                             Show Completed Cases
                                             <input type="checkbox" id="completed" className="form-control" onChange={this.showCompleted}
                                                  checked={!!showCompleted} />
                                             <span className="checkmark"></span>
                                        </label>
                                        {/* <label className="container_Checkbox margin0 roundedcheckbox right sub-heading2 mr15">
                                             Show Second Level Qc Cases
                                             <input type="checkbox" id="secondLevelQc" className="form-control" onChange={this.handleSecondLevelQc}
                                                  checked={!!showSecondLevelQc} />
                                             <span className="checkmark"></span>
                                        </label> */}
                                        <label className="container_Checkbox margin0 roundedcheckbox right sub-heading2 mr15">
                                             Show Refer Back Cases
                                             <input type="checkbox" id="referBack" className="form-control" onChange={this.handleReferBack}
                                                  checked={!!showReferBack} />
                                             <span className="checkmark"></span>
                                        </label>
                                   </div>
                              </>
                              : null}
                         {filtersTags}
                         {this.state.loading === true ?

                              <div className='sub-heading1 pad10 text-center' style={{ height: '60px' }}> Loading....</div>
                              : <Casereporttable caseList={this.state.completeData} paramsObj={this.paramsObj} enableFilters={this.state.enableFilters} setActivePage={this.setActivePage} fetchCompleteData={this.fetchFilteredCaseList} activePage={this.activePage} totalCount={this.state.totalCount} clicked={this.loadMore} load={this.state.showLoadMore} scrollTop={this.scrollTop} dataEntryView={this.props.dataEntryView} qcView={this.props.qcView} qcOperationsView={this.props.qcOperationsView} showChildTable={this.showChildTable} activeRow={this.state.activeRow} showSecondLevelQc={showSecondLevelQc} priorityQcData={priorityQcData} />}
                    </div>
                    <div className="clr20"></div>
                    <div className="clr20"></div>
               </React.Fragment>
          )
     }
}
export default withTranslation("translations")(Casereportdetails);