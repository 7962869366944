import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import ConfirmationModal from '../globals/uicomps/ConfirmationModal';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';

class VerificationStatusPanel extends Component {

     constructor(props) {
          super(props);
          this.state = {
               showConfirmationModal: false,
               subheading: '',
               loading: false,
               payloadData: null
          }
          this.code = '';
          this.caseVerifiDetailId = '';
          this.userid = UserInfo.getUserid();
          getValue().then((pLData) => {
               this.setState({ payloadData: pLData });
          });
     }

     showDeepTrack = (code, caseVerifiDetailId) => {
          this.props.showDeepTrack(code, caseVerifiDetailId, true);
     }

     confirmSuccessCallback = () => {
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/deleteVerificationFromCase`)
          this.setState({ loading: false }, () => {
               this.dataSource.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   showConfirmationModal: false,
                                   loading: false
                              })
                              this.props.showDeepTrack(this.code, this.caseVerifiDetailId, false);
                         }
                    },
                    { "caseId": this.props.caseId, "code": this.code, "caseVerifiDetailId": this.caseVerifiDetailId, userId: this.userid },
                    "POST"
               );
          });
     }

     deleteVerificationFromCase = (code, caseVerifiDetailId) => {
          this.code = code
          this.caseVerifiDetailId = caseVerifiDetailId
          this.setState({
               showConfirmationModal: true,
               subheading: 'Do you want to delete this check'
          })
     }

     render = () => {
          if (this.state.payloadData) {
               const { t } = this.props
               let isAdminOrChp = this.state.payloadData && this.state.payloadData['isAdminOrChp'];
               let canDeleteVerification = ((this.state.payloadData && isAdminOrChp) || this.state.payloadData['roleCode'] === 'reportviewer') && this.props.enableEdit;
               let name = this.props.value['name']
               let status = this.props.value['status']
               let code = this.props.value['code']
               let caseVerifiDetailId = this.props.value['caseVerifiDetailId']
               let documentsPresent = this.props.value['sourceDocPresent'] || this.props.value['evidenceDocPresent']
               let statuscode = (status === "Failed" ? 'close' : (status === "Insufficiency" ? 'warning' : status === "Clear" ? 'green-tick' : 'inprogress'))
               let colorClass = (status === "Failed" ? 'error-text' : (status === "Insufficiency" ? 'inprogress' : status === "Clear" ? 'completed' : 'primary-color'))
               return (
                    this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                         <div className={`panel-card ${(this.props.completeState.caseVerifiDetailId === caseVerifiDetailId && this.props.completeState.deepTrack === true) ? "highLightCard" : ""}`}>
                              <div className="sub-heading2">{name}
                                   {canDeleteVerification ? <img alt="icon-close" src={'/images/icons/icon-close.svg'} className="remove_vendor pointer" onClick={() => { this.deleteVerificationFromCase(code, caseVerifiDetailId) }} /> : null}
                              </div>
                              <div className="clr5"></div>
                              <div className="component-icons">
                                   <img src={"/images/component-icons/component-" + statuscode + ".svg"} alt="icon" />
                                   <span className={colorClass}>{status}</span>
                              </div>
                              <div className="clr15"></div>
                              <div className="smallfont" onClick={() => { this.showDeepTrack(code, caseVerifiDetailId); }}>
                                   <button className="btn btn_teritary track-progress">{t('Track Progress')}</button>
                              </div>
                              <div className="clr15"></div>
                              {documentsPresent ? (
                                   <div className="file-area">
                                        {/* {console.log("profilelink", this.props.value.profileLink, this.props.value.documentLink)} */}
                                        <div className="file-row row half-block-download">
                                             <div className="col-6">
                                                  {this.props.value.sourceDocPresent ?
                                                       <a className="file-btn download-btn on-plainbg" href={this.props.value.documentLink} title="Download User Documents" >
                                                            <div className="file-name body2 smallfont">Source Documents </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                 <path d="M15.558,42.357,8.951,48.987a.536.536,0,0,1-.759,0L1.585,42.357a.536.536,0,0,1,0-.759l.317-.317a.536.536,0,0,1,.759,0L7.813,46.46V32.536A.537.537,0,0,1,8.348,32h.446a.537.537,0,0,1,.536.536V46.46l5.152-5.174a.536.536,0,0,1,.759,0l.317.317A.531.531,0,0,1,15.558,42.357Zm1.585,9.107v-.357a.537.537,0,0,0-.536-.536H.536A.537.537,0,0,0,0,51.107v.357A.537.537,0,0,0,.536,52H16.607A.537.537,0,0,0,17.143,51.464Z" transform="translate(3 -30)"></path>
                                                            </svg>
                                                       </a>
                                                       : ''}
                                             </div>
                                             <div className="col-6">
                                                  {this.props.value.evidenceDocPresent ?
                                                       <a className="file-btn download-btn on-plainbg" href={this.props.value.profileLink} title="Download Evidence Documents" >
                                                            <div className="file-name body2 smallfont">Evidence Documents </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                 <path d="M15.558,42.357,8.951,48.987a.536.536,0,0,1-.759,0L1.585,42.357a.536.536,0,0,1,0-.759l.317-.317a.536.536,0,0,1,.759,0L7.813,46.46V32.536A.537.537,0,0,1,8.348,32h.446a.537.537,0,0,1,.536.536V46.46l5.152-5.174a.536.536,0,0,1,.759,0l.317.317A.531.531,0,0,1,15.558,42.357Zm1.585,9.107v-.357a.537.537,0,0,0-.536-.536H.536A.537.537,0,0,0,0,51.107v.357A.537.537,0,0,0,.536,52H16.607A.537.537,0,0,0,17.143,51.464Z" transform="translate(3 -30)"></path>
                                                            </svg>
                                                       </a>
                                                       : ''}
                                             </div>
                                             <div className="clr"></div>
                                        </div>
                                        <div className="clr"></div>
                                   </div>
                              ) : ' '
                              }
                         </div>
                         <ConfirmationModal
                              show={this.state.showConfirmationModal}
                              heading="Are you sure ?"
                              subheading={this.state.subheading}
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.confirmSuccessCallback() }}
                              failureCallback={() => { this.setState({ showConfirmationModal: false }) }}
                              closeCallback={() => { this.setState({ showConfirmationModal: false }) }}
                         ></ConfirmationModal>
                    </React.Fragment>
               );
          }

     }
}
export default withTranslation('translations')(VerificationStatusPanel);