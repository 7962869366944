import React, { Component } from 'react'
import Spinner  from 'react-bootstrap/Spinner';

export class LoadingSpinner extends Component {
     render = () => {
          var spinnerStyle = {
               padding: 0,
               left:'50%',
               top:'50%',
               position:'absolute',
               transform: 'translate(-50%,-50%)'
          };

          var loaderBg = {
               left: "0%",
               top: "0%",
               width: "100%",
               height: "100%",
               position: "absolute",
               "background-color": "#FFFFFF",
               opacity: 0.3
          };

          return (
               <>
                    <div style={this.props.showBg === "true"?loaderBg:{}}/>
                    <div style={spinnerStyle}>
                         <Spinner animation="border" variant="info" />
                    </div> 
               </>
                      
                    
          );
     }
}